import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Dropdown = ({ value, options, onChange, disabled }) => {
  return (
    <Select
      value={options.filter((option) => option.value === value)}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      styles={customStyles}    />
  );
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: '1px solid #2BB673',
    borderRadius: 5,
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #2BB673',
    },
    '&:focus': {
      border: '2px solid #2BB673',
    },
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? '#2BB673' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
};


Dropdown.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.bool,
};

Dropdown.defaultProps = {
  value: '',
  onChange: () => {},
  options: [],
  disabled: false,
};

export default Dropdown;
