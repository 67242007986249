/* eslint react/button-has-type: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({ children, type, onClick, className, loading, red, active }) => {
  return (
    <button
      type={type}
      className={classNames(
        `border rounded-lg px-4 py-2 m-2 ${
          red ? 'border-red' : 'border-primary'
        } ${
          active ? 'bg-primary text-white' : 'bg-transparent text-black'
        }`,
        className
      )}
      onClick={onClick}
      disabled={loading}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  red: PropTypes.bool,
  active: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  className: '',
  onClick: () => {},
  loading: false,
  type: 'button',
  red: false,
  active: false,
};

export default Button;
