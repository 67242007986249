import { Base64 } from 'js-base64';
import { getCookie, setCookie, deleteCookie } from '../../utils/cookies';
const qs = require('query-string');

const apiURL = 'https://api.gardenapp.eu/'
// const apiURL = 'http://localhost:9000';
//const apiURL = 'https://gardenbackend.herokuapp.com/';
const masterKey = 'YBntyb7vEN0kDCHUh6qYsspR8O0uHL6J';

const axios = require('axios');

export function saveTokenToRedux(token) {
  return async (dispatch) => {
    dispatch({ type: 'SAVE_TOKEN_TO_REDUX', data: token });
    dispatch(getMe());
  };
}

export function getToken(email, password) {
  return async (dispatch) => {
    return axios
      .post(
        apiURL + '/auth?access_token=' + masterKey,
        {},
        {
          headers: {
            Authorization: 'Basic ' + Base64.encode(email + ':' + password),
          },
        }
      )
      .then((response) => {
        const role = response.data.user.role;
        if (role === 'admin') {
          setCookie('token', response.data.token, 30);
          setCookie('userid', response.data.user.id, 30);
          setCookie('userrole', role, 30);
          return dispatch({
            type: 'LOGIN_SUCCESS',
            data: response.data,
          });
        } else {
          alert('Nincs felhatalmazásod a belépéshez');
          dispatch({ type: 'LOGIN_FAILED', data: 'Hiba a belépésnél' });
        }
      })
      .catch(function (error) {
        alert('Hiba, kérlek próbáld újra');
        if (error.response) {
          if (error.response.status === 401) {
            dispatch({ type: 'LOGIN_FAILED', data: 'Hiba a belépésnél' });
          } else {
            dispatch({
              type: 'LOGIN_FAILED',
              data: 'Rossz felhasználónév vagy jelszó',
            });
          }
        }
        dispatch({
          type: 'LOGIN_FAILED',
          data: 'Login failed',
        });
      });
  };
}

export function logOut() {
  return async (dispatch) => {
    deleteCookie('token');
    deleteCookie('userid');
    deleteCookie('userrole');
    dispatch({ type: 'LOGOUT' });
  };
}

export function getMe() {
  return async (dispatch) => {
    const access_token = getCookie('token');
    return axios
      .get(apiURL + '/users/me', {
        headers: { Authorization: 'Bearer ' + access_token },
      })
      .then((response) => {
        return dispatch({
          type: 'GET_ME',
          data: response.data,
        });
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Lejárt a munkameneted, kérlek lépj be újra.');
            dispatch(logOut());
          } else {
            dispatch({
              type: 'GET_ME_FAILED',
              data: 'Rossz felhasználónév vagy jelszó',
            });
          }
        }
        dispatch({
          type: 'LOGIN_FAILED',
          data: 'Login failed',
        });
      });
  };
}

export const getSettings = () => (dispatch) => {
  dispatch({ type: 'GET_OBJECTS_LOADING' });
  axios
    .get(apiURL + '/settings', {
      headers: { Authorization: 'Bearer ' + masterKey },
    })
    .then((response) => {
      dispatch({ type: 'GET_SETTINGS', data: response.data });
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert('Lejárt a munkameneted, kérlek lépj be újra.');
          dispatch(logOut());
        } else {
          dispatch({ type: 'GET_CATEGORY_ERROR', data: error.message });
        }
      }
    });
};

export const getCategories = () => (dispatch) => {
  dispatch({ type: 'GET_OBJECTS_LOADING' });
  axios
    .get(apiURL + '/category', {
      headers: { Authorization: 'Bearer ' + masterKey },
    })
    .then((response) => {
      dispatch({ type: 'GET_CATEGORY', data: response.data });
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert('Lejárt a munkameneted, kérlek lépj be újra.');
          dispatch(logOut());
        } else {
          dispatch({ type: 'GET_CATEGORY_ERROR', data: error.message });
        }
      }
    });
};

export const getEditorObjects = () => (dispatch) => {
  dispatch({ type: 'GET_OBJECTS_LOADING' });
  axios
    .get(apiURL + '/editorobjects/admin', {
      headers: { Authorization: 'Bearer ' + masterKey },
    })
    .then((response) => {
      dispatch({ type: 'GET_EDITOR_OBJECTS', data: response.data });
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert('Lejárt a munkameneted, kérlek lépj be újra.');
          dispatch(logOut());
        } else {
          dispatch({ type: 'GET_EDITOR_OBJECTS_ERROR', data: error.message });
        }
      }
    });
};

export const getGroundObjects = () => (dispatch) => {
  dispatch({ type: 'GET_OBJECTS_LOADING' });
  axios
    .get(apiURL + '/groundobjects/admin', {
      headers: { Authorization: 'Bearer ' + masterKey },
    })
    .then((response) => {
      dispatch({ type: 'GET_GROUND_OBJECTS', data: response.data });
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert('Lejárt a munkameneted, kérlek lépj be újra.');
          dispatch(logOut());
        } else {
          dispatch({ type: 'GET_GROUND_OBJECTS_ERROR', data: error.message });
        }
      }
    });
};

export const getBanners = () => (dispatch) => {
  dispatch({ type: 'GET_BANNERS_LOADING' });
  axios
    .get(apiURL + '/banner', {
      headers: { Authorization: 'Bearer ' + masterKey },
    })
    .then((response) => {
      console.log("GET BANNERS RESPONSE", response.data);
      dispatch({ type: 'GET_BANNERS', data: response.data });
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          alert('Lejárt a munkameneted, kérlek lépj be újra.');
          dispatch(logOut());
        } else {
          dispatch({ type: 'GET_BANNERS_ERROR', data: error.message });
        }
      }
    });
};

export const create = (body, url) => {
  return async (dispatch, getState) => {
    return axios
      .post(apiURL + url, qs.stringify(body), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + getState().auth.token,
        },
      })
      .then(async (response) => {
        dispatch(getWhatsUpdated(url));
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Lejárt a munkameneted, kérlek lépj be újra.');
            dispatch(logOut());
          } else {
            dispatch({
              type: 'ERROR',
            });
          }
        }
      });
  };
};

export const put = (body, url, id) => {
  return async (dispatch, getState) => {
    return axios
      .put(apiURL + url + '/' + id, qs.stringify(body), {
        headers: {
          Authorization: 'Bearer ' + getState().auth.token,
        },
      })
      .then(async (response) => {
        dispatch(getWhatsUpdated(url));
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            alert('Lejárt a munkameneted, kérlek lépj be újra.');
            dispatch(logOut());
          } else {
            dispatch({
              type: 'ERROR',
            });
          }
        }
      });
  };
};

export const remove = (url, id, body) => {
  return async (dispatch, getState) => {
    return axios
      .delete(apiURL + url + '/' + id, {
        headers: {
          Authorization: 'Bearer ' + getState().auth.token,
        },
      })
      .then(async (response) => {
        dispatch(getWhatsUpdated(url));
      })
      .catch(function (error) {
        console.log(error);
        if (error.response) {
          console.log(error.response);
          if (error.response.status === 401) {
            alert('Lejárt a munkameneted, kérlek lépj be újra.');
            dispatch(logOut());
          } else {
            dispatch({
              type: 'ERROR',
            });
          }
        }
      });
  };
};

export const getWhatsUpdated = (url) => {
  return async (dispatch, getState) => {
    if (url === '/banner') {
      dispatch(getBanners());
    } else {
      dispatch(getCategories());
      dispatch(getEditorObjects());
      dispatch(getGroundObjects());
    }
  };
};