import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../button';
import BannerForm from './BannerForm';
import Dropdown from '../dropdown';

const BannersTable = () => {
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [newBanner, setNew] = useState(false);
  const banners = useSelector((state) => state.auth.banners);

  return (
    <div className="px-10">
       <div className="flex flex-row items-start max-w-[400px] mb-8">
        <h2 className='text-xl my-4 font-bold text-left mr-12'>Banner</h2>
       <div>
       <Button
        active={newBanner}
        onClick={() => {
          setNew(true);
          setSelectedBanner(null);
        }}
        className="min-w-[100px] bg-lighter-grey"
      >
       +
      </Button>
      <p className='text-base font-bold text-left'>max 5 bannert érdemes feltölteni</p>
      </div>
      </div>
      <div className='max-w-[400px]'>
        <Dropdown
          value={selectedBanner}
          options={banners.map((banner) => ({
            value: banner.id,
            label: banner.order + ' - ' + banner.title_hu,
          }))}
          
          onChange={(value) => {
            setNew(false);
            console.log("VALUE SELECTED", value)
            setSelectedBanner(value.value);
          }}
        />
      </div>


      {(selectedBanner || newBanner) && (
        <BannerForm
          selectedBanner={selectedBanner}
          banner={banners.find((banner) => banner.id === selectedBanner)}
          new={newBanner}
          onClose={() => {
            setNew(false);
            setSelectedBanner(null);
          }}
        />
      )}
    </div>
  );
};

export default BannersTable;
