import React from 'react';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import rootReducer from './redux/reducers/index';
import Login from './screens/Login';
import Admin from './screens/Admin';

const middlewares = [thunk];
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter //</Provider>history={history}
      >
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route path='/admin' element={<Admin />} />
          <Route path='*' element={<Navigate to='' />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
