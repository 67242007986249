import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { put } from '../redux/actions/auth';
import Button from './button';
import TextField from './textfield';

const SubCategoryForm = (props) => {
  const dispatch = useDispatch();
  const categoryList = useSelector((state) => state.auth.category);
  const [id, setId] = useState('');
  const [title_hu, setTitleHu] = useState('');
  const [title_en, setTitleEn] = useState('');
  const [title_de, setTitleDe] = useState('');
  const [title_es, setTitleEs] = useState('');
  const [title_ru, setTitleRu] = useState('');
  const [title_zh, setTitleZh] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (props.category && categoryList.length !== 0) {
      const selectedCategory = categoryList.find(
        (cat) => cat.category === props.category
      );
      setId(selectedCategory.id);
      setTitleHu(selectedCategory.title_hu);
      setTitleEn(selectedCategory.title_en);
      setTitleDe(selectedCategory.title_de);
      setTitleEs(selectedCategory.title_es);
      setTitleRu(selectedCategory.title_ru);
      setTitleZh(selectedCategory.title_zh);
      setCategory(selectedCategory.category);
    }
  }, [props.category, categoryList]);

  const onSubmit = () => {
    const subcat = {
      title_hu,
      title_en,
      title_de,
      title_es,
      title_ru,
      title_zh,
      category: category,
    };
    dispatch(put(subcat, '/category', id));
  };

  return (
    <div className='flex flex-col w-full'>
      <div className='text-xl my-4 font-bold text-left mb-8'>{title_hu}</div>
      <div className='max-w-[400px]'>
        <TextField
          type='text'
          label='Név hu'
          name='title_hu'
          value={title_hu}
          onChange={(e) => setTitleHu(e.target.value)}
        />
        <TextField
          type='text'
          label='Név en'
          name='title_en'
          value={title_en}
          onChange={(e) => setTitleEn(e.target.value)}
        />
        <TextField
          type='text'
          label='Név de'
          name='title_de'
          value={title_de}
          onChange={(e) => setTitleDe(e.target.value)}
        />
        <TextField
          type='text'
          label='Név es'
          name='title_es'
          value={title_es}
          onChange={(e) => setTitleEs(e.target.value)}
        />
        <TextField
          type='text'
          label='Név ru'
          name='title_ru'
          value={title_ru}
          onChange={(e) => setTitleRu(e.target.value)}
        />
        <TextField
          type='text'
          label='Név zh'
          name='title_zh'
          value={title_zh}
          onChange={(e) => setTitleZh(e.target.value)}
        />
      </div>
      <div className='w-full flex flex-row ml-auto'>
        <Button type='submit' className="ml-auto" active onClick={() => onSubmit()}>
          Mentés
        </Button>
      </div>
    </div>
  );
};

export default SubCategoryForm;
