/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { create, put, remove } from "../redux/actions/auth";
import Button from "./button";
import TextField from "./textfield";
import ImageUploading from "react-images-uploading";
import axios from "axios";

const apiURL = "https://api.gardenapp.eu/"; //'http://localhost:9000

const ObjectForm = (props) => {
  const dispatch = useDispatch();
  //const [image_url, setImageUrl] = useState('');
  const [title_hu, setTitleHu] = useState("");
  const [title_en, setTitleEn] = useState("");
  const [title_de, setTitleDe] = useState("");
  const [title_es, setTitleEs] = useState("");
  const [title_ru, setTitleRu] = useState("");
  const [title_zh, setTitleZh] = useState("");
  const [description_hu, setDescriptionHu] = useState("");
  const [description_en, setDescriptionEn] = useState("");
  const [description_de, setDescriptionDe] = useState("");
  const [description_es, setDescriptionEs] = useState("");
  const [description_ru, setDescriptionRu] = useState("");
  const [description_zh, setDescriptionZh] = useState("");
  const [order, setOrder] = useState(0);
  const [link_hu, setLinkHu] = useState("");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    if (props.object && !props.new) {
      setImagePath(props.object.image_url);
      setImageUploaded(true);
      setTitleHu(props.object.title_hu);
      setTitleEn(props.object.title_en);
      setTitleDe(props.object.title_de);
      setTitleEs(props.object.title_es);
      setTitleRu(props.object.title_ru);
      setTitleZh(props.object.title_zh);
      setDescriptionHu(props.object.description_hu);
      setDescriptionEn(props.object.description_en);
      setDescriptionDe(props.object.description_de);
      setDescriptionEs(props.object.description_es);
      setDescriptionRu(props.object.description_ru);
      setDescriptionZh(props.object.description_zh);
      setOrder(props.object.order);
      setLinkHu(props.object.link_hu);
    } else {
      setImagePath(null);
      setTitleHu("");
      setTitleEn("");
      setTitleDe("");
      setTitleEs("");
      setTitleRu("");
      setTitleZh("");
      setDescriptionHu("");
      setDescriptionEn("");
      setDescriptionDe("");
      setDescriptionEs("");
      setDescriptionRu("");
      setDescriptionZh("");
      setOrder(0);
      setLinkHu("");
    }
  }, [props.object, props.new]);

  const onSubmit = () => {
    const objectToSave = {
      image_url: imagePath,
      title_hu,
      title_en,
      title_de,
      title_es,
      title_ru,
      title_zh,
      description_hu,
      description_en,
      description_de,
      description_es,
      description_ru,
      description_zh,
      order: order,
      link_hu,
      category: props.selectedCategory,
    };
    if (props.new) {
      if (
        props.selectedCategory === "talajtakaro" ||
        props.selectedCategory === "terburkolat"
      ) {
        dispatch(create(objectToSave, "/groundObjects"));
      } else {
        dispatch(create(objectToSave, "/editorObjects"));
      }
    } else {
      if (
        props.selectedCategory === "talajtakaro" ||
        props.selectedCategory === "terburkolat"
      ) {
        dispatch(put(objectToSave, "/groundObjects", props.object.id));
      } else {
        dispatch(put(objectToSave, "/editorObjects", props.object.id));
      }
    }
    props.onClose();
  };

  const onDelete = () => {
    if (
      props.selectedCategory === "talajtakaro" ||
      props.selectedCategory === "terburkolat"
    ) {
      dispatch(remove("/groundObjects", props.object.id));
    } else {
      dispatch(remove("/editorObjects", props.object.id));
    }
    props.onClose();
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  if (props.object?.hardcoded && !props.new) {
    return (
      <div className="flex flex-col">
        <div className="text-xl my-4 font-bold text-center mb-8">
          Beégetett Objektum
        </div>
        <img src={imagePath} alt="obj img" className="h-40 w-40 mb-10" />
        <div className="flex flex-row">
          <div className="min-w-[400px] mr-[100px]">
            <p>Név hu: {title_hu}</p>
            <p>Név en: {title_en}</p>
            <p>Név de: {title_de}</p>
            <p>Név es: {title_es}</p>
            <p>Név ru: {title_ru}</p>
            <p>Név zh: {title_zh}</p>
          </div>
          <div className="">
            <p className="font-bold">Leírás hu:</p>
            <p className="mb-2">{description_hu}</p>
            <p className="font-bold">Leírás en:</p>
            <p className="mb-2">{description_en}</p>
            <p className="font-bold">Leírás de:</p>
            <p className="mb-2">{description_de}</p>
            <p className="font-bold">Leírás es:</p>
            <p className="mb-2">{description_es}</p>
            <p className="font-bold">Leírás ru:</p>
            <p className="mb-2">{description_ru}</p>
            <p className="font-bold">Leírás zh:</p>
            <p className="mb-2">{description_zh}</p>
          </div>
        </div>
        <p>Kép sorszáma: {order}</p>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col">
        <p className="my-4">Kép kiválasztása:</p>
        {imageUploaded ? (
          <div className="flex flex-row items-start mb-12">
            <img src={imagePath} alt="obj img" className="h-40 w-40" />
            <div className="flex flex-row items-center">
              <Button
                onClick={() => {
                  setImagePath("");
                  setImageUploaded(false);
                }}
                className="bg-light-gray"
              >
                Fájl feltöltése
              </Button>
              <p>{imagePath}</p>
            </div>
          </div>
        ) : (
          <ImageUploading
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
            //maxFileSize={1000000}
          >
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
              onImageRemove,
              errors,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="flex flex-row mb-12">
                {imageList && imageList.length === 0 && (
                  <button
                    className={`border-2 border-dashed w-40 h-40 ${
                      isDragging && "text-green-500"
                    }`}
                    style={isDragging ? { color: "red" } : undefined}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    Kattints vagy húzd ide
                  </button>
                )}
                {imageList.map((image, index) => (
                  <div key={index} className="flex flex-row">
                    <img
                      className="border-2 border-dashed border-black mb-4 w-40 h-40"
                      src={image["data_url"]}
                      alt=""
                    />
                    <div className="ml-4">
                      <Button
                        //className='px-4 py-2 bg-primary rounded text-white font-bold mr-4'
                        onClick={() => onImageUpdate(index)}
                        className="bg-lighter-grey"
                      >
                        Új választás
                      </Button>
                      <Button
                        //className='px-4 py-2 bg-red rounded text-white font-bold mr-4'
                        className="bg-lighter-grey"
                        onClick={() => onImageRemove(index)}
                      >
                        Törlés
                      </Button>
                      <Button
                        active
                        //className='px-4 py-2 bg-red rounded text-white font-bold mr-4'
                        onClick={() => {
                          const formData = new FormData();
                          formData.append("file", images[0].file);
                          axios
                            .post(apiURL + "upload", formData, {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            })
                            .then((response) => {
                              setImageUploaded(true);
                              setImagePath(
                                "https://gardenapp.eu" +
                                  response.data.file.path.replace(
                                    "../images",
                                    ""
                                  )
                              );
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        }}
                      >
                        Feltöltés
                      </Button>
                    </div>
                  </div>
                ))}
                {errors && (
                  <div>
                    {errors.maxNumber && (
                      <span>Number of selected images exceed maxNumber</span>
                    )}
                    {errors.acceptType && (
                      <span>Your selected file type is not allow</span>
                    )}
                    {errors.maxFileSize && (
                      <span>Selected file size exceed maxFileSize</span>
                    )}
                    {errors.resolution && (
                      <span>
                        Selected file is not match your desired resolution
                      </span>
                    )}
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        )}

        <div className="flex flex-row">
          <div className="min-w-[400px] mr-[100px]">
            <TextField
              type="text"
              label="Név hu"
              name="title_hu"
              value={title_hu}
              onChange={(e) => setTitleHu(e.target.value)}
            />
            <TextField
              type="text"
              label="Név en"
              name="title_en"
              value={title_en}
              onChange={(e) => setTitleEn(e.target.value)}
            />
            <TextField
              type="text"
              label="Név de"
              name="title_de"
              value={title_de}
              onChange={(e) => setTitleDe(e.target.value)}
            />
            <TextField
              type="text"
              label="Név es"
              name="title_es"
              value={title_es}
              onChange={(e) => setTitleEs(e.target.value)}
            />
            <TextField
              type="text"
              label="Név ru"
              name="title_ru"
              value={title_ru}
              onChange={(e) => setTitleRu(e.target.value)}
            />
            <TextField
              type="text"
              label="Név zh"
              name="title_zh"
              value={title_zh}
              onChange={(e) => setTitleZh(e.target.value)}
            />
          </div>
          <div className="flex flex-col min-w-[400px] w-full">
            <TextField
              type="text"
              label="Leírás hu"
              name="description_hu"
              value={description_hu}
              onChange={(e) => setDescriptionHu(e.target.value)}
            />
            <TextField
              type="text"
              label="Leírás en"
              name="description_en"
              value={description_en}
              onChange={(e) => setDescriptionEn(e.target.value)}
            />
            <TextField
              type="text"
              label="Leírás de"
              name="description_de"
              value={description_de}
              onChange={(e) => setDescriptionDe(e.target.value)}
            />
            <TextField
              type="text"
              label="Leírás es"
              name="description_es"
              value={description_es}
              onChange={(e) => setDescriptionEs(e.target.value)}
            />
            <TextField
              type="text"
              label="Leírás ru"
              name="description_ru"
              value={description_ru}
              onChange={(e) => setDescriptionRu(e.target.value)}
            />
            <TextField
              type="text"
              label="Leírás zh"
              name="description_zh"
              value={description_zh}
              onChange={(e) => setDescriptionZh(e.target.value)}
            />
          </div>
        </div>
        <div className="min-w-[400px] mr-[100px] max-w-[400px] flex flex-row items-center">
          <p>Kép sorszáma:</p>
          <input
            name="order"
            id="order"
            type="number"
            onChange={(e) => setOrder(e.target.value)}
            value={order}
            className={`w-auto max-w-[80px] ml-10 border-primary border rounded px-4 py-1 outline-none`}
          />
        </div>
        <div className="max-w-[400px] mt-4">
        <TextField
          type="text"
          label="Link hu"
          name="link_hu"
          value={link_hu}
          onChange={(e) => setLinkHu(e.target.value)}
        />
        </div>
        <div className="w-full flex flex-row ml-auto">
          <Button
            red
            type="submit"
            active
            className="ml-auto bg-red border-red"
            onClick={() => onDelete()}
          >
            Törlés
          </Button>
          <Button
            active
            type="submit"
            className="ml-12"
            onClick={() => onSubmit()}
          >
            Mentés
          </Button>
        </div>
        <div>
        <p className="text-lg">Segítség a megfelelő linkek másolásához:</p>
        <p>Az affiliate linkek mindegyikének a vége eltér áruházanként, ezért a dognet rendszeréből kell kikeresni a helyes formátumot</p>
        <p>A dognet oldalán a Banners & Links felületre kell kattintani, és ott ki kell keresni a megfelelő webáruházat, majd van egy gomb, hogy "get banner code", ha ezt megnyomod megjelenik a link amit másolhatsz is ide.</p>
        <p>Az árukereső esetén a link legvégére kell tenni a következőt: &chan=gardenapp</p>
        <p className="text-lg">Linkek tesztelése</p>
        <p>Tesztelni a kattintást úgy tudod, hogy rákattintasz a bannerre, majd a dognet oldalán a Reports/Click list alatt megnézed hogy megtörtént-e a kattintás (1 perc lehet a frissülési idő, az oldalt újra kell töltened hogy megjelenjen).</p>
      </div>
      </div>
    );
  }
};

export default ObjectForm;
