export const initialState = {
  errorMessage: null,
  token: "",
  user: {},
  category: [],
  editorobjects: [],
  groundobjects: [],
  settings: [],
  banners: [],
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_TOKEN_TO_REDUX":
      return {
        ...state,

        token: action.data,
      };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        errorMessage: null,
      };
    case "LOGOUT":
      return {
        ...state,
        token: "",
        user: {},
        errorMessage: null,
      };

    case "UPDATE_PASSWORD_SUCCESS":
      return {
        ...state,
        user: action.data,
        errorMessage: null,
      };
    case "GET_ME":
      return {
        ...state,
        user: action.data,
        errorMessage: null,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        errorMessage: action.data,
      };
    case "GET_SETTINGS":
      return {
        ...state,
        settings: action.data,
        errorMessage: null,
      };
    case "GET_EDITOR_OBJECTS":
      return {
        ...state,
        editorobjects: action.data,
        errorMessage: null,
      };

    case "GET_GROUND_OBJECTS":
      return {
        ...state,
        groundobjects: action.data,
        errorMessage: null,
      };
    case "GET_CATEGORY":
      return {
        ...state,
        category: action.data,
        errorMessage: null,
      };
    case "GET_BANNERS":
      return {
        ...state,
        banners: action.data,
        errorMessage: null,
      };
    default:
      return state;
  }
};

export default auth;
