import React from 'react';
import { Navigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../redux/actions/auth';
import TextField from '../components/textfield';
import PasswordField from '../components/passwordfield';
import Button from '../components/button';
import { getCookie } from '../utils/cookies';

function Login() {
  const dispatch = useDispatch();
  const token = getCookie('token');
  const redux_token = useSelector((state) => state.auth.token);
  const { handleSubmit, register, errors, getValues } = useForm();
  const loginError = false;
  const loginLoading = false;

  // Login attempt
  const onSubmit = async (values) => {
    dispatch(getToken(values.email, values.password));
  };

  if (token || redux_token) {
    return <Navigate replace to='/admin' />;
  }

  return (
    <div className='w-screen h-screen p-20 bg-grey flex items-center justify-center'>
      <div className='w-5/12 h-auto p-8 bg-white shadow-md'>
        <div className='mb-4'>Bejelentkezés</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type='email'
            label='Email'
            name='email'
            {...register('email', {
              required: 'Írd be az email címedet',
            })}
            errors={errors}
          />

          <PasswordField
            label='Jelszó'
            name='password'
            {...register('password', {
              required: 'Add meg a jelszavadat',
            })}
            errors={errors}
          />
          <div className='text-red-700'>
            {loginError ? (
              <span className='inline-block text-sm mt-1'>Belépés hiba</span>
            ) : null}
          </div>

          <div className='flex mt-8 mb-8'>
            <Button
              type='submit'
              onClick={() => {
                const values = getValues();
                dispatch(getToken(values.email, values.password));
              }}
              size='md'
              className='ml-auto'
              loading={loginLoading}
            >
              login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
