/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { create, put, remove } from "../../redux/actions/auth";
import Button from "../button";
import TextField from "../textfield";
import ImageUploading from "react-images-uploading";
import axios from "axios";

// const apiURL = "http://localhost:9000/";
const apiURL = 'https://api.gardenapp.eu/';

const BannerForm = (props) => {
  const dispatch = useDispatch();
  const [title_hu, setTitleHu] = useState("");
  const [name_hu, setNameHu] = useState("");
  const [link_hu, setLinkHu] = useState("");
  const [order, setOrder] = useState(0);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    if (props.banner && !props.new) {
      setImagePath(props.banner.image_url);
      setImageUploaded(true);
      setTitleHu(props.banner.title_hu);
      setLinkHu(props.banner.link_hu);
      setNameHu(props.banner.name_hu);
      setOrder(props.banner.order);
    } else {
      setImagePath(null);
      setTitleHu("");
      setLinkHu("");
      setNameHu("");
      setOrder(0);
    }
  }, [props.banner, props.new]);

  const onSubmit = () => {
    const objectToSave = {
      image_url: imagePath,
      title_hu,
      name_hu,
      link_hu,
      order: order,
      category: props.selectedCategory,
    };
    if (props.new) {
      dispatch(create(objectToSave, "/banner"));
    } else {
      dispatch(put(objectToSave, "/banner", props.banner.id));
    }
    props.onClose();
  };

  const onDelete = () => {
    if (!props.new) {
      dispatch(remove("/banner", props.banner.id));
    }
    props.onClose();
  };

  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  return (
    <div className="flex flex-col">
      <p className="my-4">Kép kiválasztása:</p>
      {imageUploaded ? (
        <div className="flex flex-row items-start mb-12">
          <img src={imagePath} alt="obj img" className="h-[160px] w-[285px]" />
          <div className="flex flex-row items-center">
            <Button
              onClick={() => {
                setImagePath("");
                setImageUploaded(false);
              }}
              className="bg-light-gray"
            >
              Fájl feltöltése (16:9-es képarány)
            </Button>
            <p>{imagePath}</p>
          </div>
        </div>
      ) : (
        <ImageUploading
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
          //maxFileSize={1000000}
        >
          {({
            imageList,
            onImageUpload,
            onImageUpdate,
            onImageRemove,
            errors,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="flex flex-row mb-12">
              {imageList && imageList.length === 0 && (
                <button
                  className={`border-2 border-dashed h-[160px] w-[285px] ${
                    isDragging && "text-green-500"
                  }`}
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Kattints vagy húzd ide
                </button>
              )}
              {imageList.map((image, index) => (
                <div key={index} className="flex flex-row">
                  <img
                    className="border-2 border-dashed border-black mb-4 h-[160px] w-[285px]"
                    src={image["data_url"]}
                    alt=""
                  />
                  <div className="ml-4">
                    <Button
                      //className='px-4 py-2 bg-primary rounded text-white font-bold mr-4'
                      onClick={() => onImageUpdate(index)}
                      className="bg-lighter-grey"
                    >
                      Új választás
                    </Button>
                    <Button
                      //className='px-4 py-2 bg-red rounded text-white font-bold mr-4'
                      className="bg-lighter-grey"
                      onClick={() => onImageRemove(index)}
                    >
                      Törlés
                    </Button>
                    <Button
                      active
                      //className='px-4 py-2 bg-red rounded text-white font-bold mr-4'
                      onClick={() => {
                        const formData = new FormData();
                        formData.append("file", images[0].file);
                        axios
                          .post(apiURL + "upload", formData, {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          })
                          .then((response) => {
                            setImageUploaded(true);
                            setImagePath(
                              "https://gardenapp.eu" +
                                response.data.file.path.replace("../images", "")
                            );
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }}
                    >
                      Feltöltés
                    </Button>
                  </div>
                </div>
              ))}
              {errors && (
                <div>
                  {errors.maxNumber && (
                    <span>Number of selected images exceed maxNumber</span>
                  )}
                  {errors.acceptType && (
                    <span>Your selected file type is not allow</span>
                  )}
                  {errors.maxFileSize && (
                    <span>Selected file size exceed maxFileSize</span>
                  )}
                  {errors.resolution && (
                    <span>
                      Selected file is not match your desired resolution
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      )}

      <div className="flex flex-row">
        <div className="min-w-[400px] mr-[100px]">
          <TextField
            type="text"
            label="Név hu"
            name="title_hu"
            value={title_hu}
            onChange={(e) => setTitleHu(e.target.value)}
          />
          <TextField
            type="text"
            label="Link hu"
            name="link_hu"
            value={link_hu}
            onChange={(e) => setLinkHu(e.target.value)}
          />
        </div>
        <div className="flex flex-col min-w-[400px] w-full">
          <TextField
            type="text"
            label="Felirat hu"
            comment="Max 65 karakter"
            name="name_hu"
            //maxLenght="75"
            value={name_hu}
            onChange={(e) => setNameHu(e.target.value.slice(0, 65))}
          />
        </div>
      </div>
      <div className="max-w-[400px] flex flex-row items-center">
        <p>Kép sorszáma:</p>
        <input
          name="order"
          id="order"
          type="number"
          onChange={(e) => setOrder(e.target.value)}
          value={order}
          className={`w-auto max-w-[80px] ml-10 border-primary border rounded px-4 py-1 outline-none`}
        />
      </div>
      <div className="w-full flex flex-row ml-auto">
        <Button
          red
          type="submit"
          active
          className="ml-auto bg-red border-red"
          onClick={() => onDelete()}
        >
          Törlés
        </Button>
        <Button
          active
          type="submit"
          className="ml-12"
          onClick={() => onSubmit()}
        >
          Mentés
        </Button>
      </div>
      <div>
        <p className="text-lg">Segítség a megfelelő linkek másolásához:</p>
        <p>Az affiliate linkek mindegyikének a vége eltér áruházanként, ezért a dognet rendszeréből kell kikeresni a helyes formátumot</p>
        <p>A dognet oldalán a Banners & Links felületre kell kattintani, és ott ki kell keresni a megfelelő webáruházat, majd van egy gomb, hogy "get banner code", ha ezt megnyomod megjelenik a link amit másolhatsz is ide.</p>
        <p>Az árukereső esetén a link legvégére kell tenni a következőt: &chan=gardenapp</p>
        <p className="text-lg">Linkek tesztelése</p>
        <p>Tesztelni a kattintást úgy tudod, hogy rákattintasz a bannerre, majd a dognet oldalán a Reports/Click list alatt megnézed hogy megtörtént-e a kattintás (1 perc lehet a frissülési idő, az oldalt újra kell töltened hogy megjelenjen).</p>
      </div>
    </div>
  );
};

export default BannerForm;
