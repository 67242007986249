import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { put } from '../redux/actions/auth';
import TextField from './textfield';
import Button from './button';

const SettingsModal = ({ isOpen, onClose, children }) => {
  const dispatch = useDispatch();
  const [aszfVersion, setAszfVersion] = useState('');
  const settings = useSelector((state) => state.auth.settings);

  useEffect(() => {
    if (settings.length > 0) {
      setAszfVersion(settings[0].aszf_version);
    }
  }, [settings]);

  const modalClasses = isOpen
    ? 'fixed inset-0 overflow-y-auto flex items-center justify-center'
    : 'hidden';

  return (
    <div className={modalClasses}>
      <div className='fixed inset-0 bg-black opacity-50'></div>
      <div className='bg-white p-8 rounded shadow-lg z-50'>
        <TextField
          type='text'
          label='ÁSZF verzió'
          name='aszf'
          defaultValue={aszfVersion}
          onChange={(e) => setAszfVersion(e.target.value)}
        />
        <Button type='submit' className='' onClick={() => onClose()}>
          Bezárás
        </Button>
        <Button
          active
          type='submit'
          className=''
          onClick={() => {
            dispatch(
              put({ aszf_version: aszfVersion }, '/settings', settings[0].id)
            );
            onClose();
          }}
        >
          Mentés
        </Button>
      </div>
    </div>
  );
};

export default SettingsModal;
