/* eslint react/forbid-prop-types: 0 */
import React from 'react';

const TextField = React.forwardRef(
  (
    {
      id,
      name,
      label,
      type,
      errors,
      groupClassName,
      fieldClassName,
      value,
      defaultValue,
      onChange,
      disabled,
      comment,
      ...rest
    },
    ref
  ) => {
    const hasError = !!errors && !!errors[name];

    return (
      <div className="flex flex-col mb-4">
      <div
        className={`relative flex flex-row items-center ${
          groupClassName || ''
        }`}
      >
        <label htmlFor={id || name} className={`w-[100px] mr-4`}>
          {label}
        </label>
        <input
          name={name}
          id={id || name}
          type={type}
          ref={ref}
          disabled={disabled}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue || ''}
          className={`w-full border-primary border rounded px-4 py-1 outline-none ${
            fieldClassName || ''
          }`}
          {...rest}
        />
      </div>
      <div className="text-xs text-gray-500">{comment}</div>
        {hasError ? <div className="text-red">{errors[name].message}</div> : null}
      </div>
    );
  }
);

export default TextField;
