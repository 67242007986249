import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from './button';
import SubCategoryForm from './SubCategoryForm';
import ObjectForm from './ObjectForm';
import Dropdown from './dropdown';

const ObjectsTable = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [object, setObject] = useState(null);
  const [newObject, setNewObject] = useState(false);
  const categoryList = useSelector((state) => state.auth.category);
  const editorobjects = useSelector((state) => state.auth.editorobjects);
  const groundobjects = useSelector((state) => state.auth.groundobjects);

  const ObjectOptions = () => {
    const objectsoptions = [];
    if (
      selectedCategory === 'talajtakaro' ||
      selectedCategory === 'terburkolat'
    ) {
      groundobjects
        .sort((a, b) => (a.order || 999) - (b.order || 999))
        .forEach((item) => {
          if (item.category === selectedCategory) {
            objectsoptions.push({ value: item.id, label: item.order + '. - ' + item.title_hu });
          }
        });
    } else {
      editorobjects
        .sort((a, b) => (a.order || 999) - (b.order || 999))
        .forEach((item) => {
          if (item.category === selectedCategory) {
            objectsoptions.push({ value: item.id, label: item.order + '. - ' + item.title_hu });
          }
        });
    }

    return objectsoptions;
  };

  const editobjectcategories = [
    'diszfu',
    'lagyszaru',
    'fa',
    'cserje',
    'butor',
    'architektura',
    'medence',
  ];

  const getCategorieNames = (category) => {
    const title = categoryList && categoryList.length !== 0 && categoryList?.filter((e) => e.category === category)[0].title_hu
    return title || category;
  }

  const groundobjectcategories = ['talajtakaro', 'terburkolat'];

  return (
    <div className="px-10">
      <h2 className='text-xl font-bold'>Kategóriák</h2>
      <h3>Objektum:</h3>

      <div className='w-full flex-wrap'>
        {editobjectcategories.map((object, index) => (
          <Button
            key={index}
            active={selectedCategory === object}
            onClick={() => {
              setNewObject(false);
              setSelectedCategory(object);
              setSelectedObject(null);
            }}
          >
            {getCategorieNames(object)}
          </Button>
        ))}
      </div>
      <h3>Talaj:</h3>
      <div className='w-full flex-wrap'>
        {groundobjectcategories.map((object, index) => (
          <Button
            key={index}
            active={selectedCategory === object}
            onClick={() => {
              setNewObject(false);
              setSelectedCategory(object);
              setSelectedObject(null);
            }}
          >
            {getCategorieNames(object)}
          </Button>
        ))}
      </div>
      {selectedCategory && <SubCategoryForm category={selectedCategory} />}

      {selectedCategory && (
      <div className="flex flex-row items-center max-w-[400px] mb-8">
        <h2 className='text-xl my-4 font-bold text-left mr-12'>Objektum</h2>
       <Button
        active={newObject}
        onClick={() => {
          setNewObject(true);
          setSelectedObject(null);
        }}
        className="min-w-[100px] bg-lighter-grey"
      >
       +
      </Button>
      </div>)}

  {selectedCategory && (
     
      <div className='max-w-[400px]'>
        <Dropdown
          value={selectedObject}
          options={ObjectOptions()}
          onChange={(value) => {
            setNewObject(false);
            setSelectedObject(value.value);
            if (
              selectedCategory === 'talajtakaro' ||
              selectedCategory === 'terburkolat'
            ) {
              const objectToEdit = groundobjects.find(
                (object) => object.id === value.value
              );
              setObject(objectToEdit);
            } else {
              const objectToEdit = editorobjects.find(
                (object) => object.id === value.value
              );
              setObject(objectToEdit);
            }
          }}
        />
      </div>
  )}

      {selectedCategory && (selectedObject || newObject) && (
        <ObjectForm
          selectedCategory={selectedCategory}
          object={object}
          new={newObject}
          onClose={() => {
            setNewObject(false);
            setSelectedObject(null);
          }}
        />
      )}
    </div>
  );
};

export default ObjectsTable;
