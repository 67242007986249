/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Button from '../components/button';
import { getCookie } from '../utils/cookies';
import { useDispatch, useSelector } from 'react-redux';
import { getBanners, getCategories, getEditorObjects, getGroundObjects, getSettings, logOut, saveTokenToRedux } from '../redux/actions/auth';
import { Navigate } from 'react-router-dom';
import ObjectsTable from '../components/ObjectsTable';
import BannersTable from '../components/banner/BannersTable';
import SettingsModal from '../components/SettingsModal';

function Admin() {
  const dispatch = useDispatch();
  const role = getCookie('userrole');
  const token = getCookie('token');
  const redux_token = useSelector((state) => state.auth.token);
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  const [tab, setTab] = useState('objects');
  useEffect(() => {
    dispatch(getEditorObjects());
    dispatch(getGroundObjects());
    dispatch(getCategories());
    dispatch(getSettings());
    dispatch(getBanners());
    if (!token) {
      return <Navigate replace to='/' />;
    }
    if (token && !redux_token) {
      dispatch(saveTokenToRedux(token));
    }
  }, [redux_token, token]);

  // if there is not user, redirect to the login page
  if (!token) {
    return <Navigate replace to='/' />;
  }

  return (
    <div className='w-screen min-h-screen flex items-center justify-center bg-grey'>
      <div className='flex flex-col w-full mx-8 my-8 min-h-screen bg-white h-auto p-8 pr-12 shadow-md'>
        <div className='flex flex-row justify-between'>
          <div className='text-4xl my-4 text-center font-bold px-10'>
            Garden App Admin
          </div>
          <Button
            type='button'
            className=''
            onClick={() => setTab('objects')}
            active={tab === 'objects'}
          >
            Objektumok
          </Button>
          <Button
            type='button'
            className=''
            onClick={() => setTab('banners')}
            active={tab === 'banners'}
          >
            Bannerek
          </Button>
          <Button
            type='button'
            className=''
            onClick={() => setSettingsIsOpen(!settingsIsOpen)}
          >
            Ászf verzió
          </Button>
          <Button
            type='button'
            className=''
            red
            onClick={() => dispatch(logOut())}
          >
            Kilépés
          </Button>
        </div>
        {role === 'admin' && (
          
          <div className="mb-40">
            {tab === 'objects' && <ObjectsTable />}
            {tab === 'banners' && <BannersTable />}
          </div>
        )}
        <SettingsModal isOpen={settingsIsOpen} onClose={() => setSettingsIsOpen(!settingsIsOpen)}/>
      </div>
    </div>
  );
}

export default Admin;
